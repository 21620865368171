@font-face {
  font-family: PublicSans;
  src: url(../public/assets/fonts/PublicSans-VariableFont_wght.ttf);
}

@font-face {
  font-family: PublicSans-Italic;
  src: url(../public/assets/fonts/PublicSans-Italic-VariableFont_wght.ttf);
}

@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");

body {
  font-family: Merriweather, serif;
  text-align: center;
  font-size: 1.2rem;
}

li {
  font-size: 1.2rem;
}

.show {
  visibility: visible;
}

.hide {
  visibility: none;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.accent {
  color: #0f4c81;
}

.section__heading {
  margin: 2rem;
}

.appLogo {
  width: 30rem;
  margin: auto;
}

.startup__container {
  border-radius: 0.25rem;
  padding: 15rem;
  color: black;
}

.onboarding__container,
.dietaryInfo__container,
.login__container {
  background-color: #519d87;
  color: #f5f5f5;
  min-height: fit-content;
  width: 60vw;
  margin: auto;
  border-radius: 2.5rem;
  padding: 2.5rem;
  filter: drop-shadow(0.2rem 0.2rem 0.5rem black);
}

.onboarding__container {
  margin: auto;
}

.onboarding__container form {
  margin: 0 auto;
}

.login__container {
  margin: 20vh auto;
  min-width: fit-content;
}

.onboarding__container h1 {
  margin: 0;
}

.signUp__container {
  padding: 1.5rem;
  min-height: fit-content;
  margin: 0 auto;
}

.signUp {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
}

.login {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.signUp__container p {
  margin-top: 1rem;
}

.signUp__button,
.login__button {
  border: none;
  border-radius: 1rem;
  background-color: #f5f5f5;
  cursor: pointer;
  outline: none;
  padding: 0.5rem;
}

.signUp__button:hover,
.login__button:hover {
  background-color: #0f4c81;
  color: #f5f5f5;
}

.signUp input,
.login input {
  border-radius: 1rem;
  padding: 0.5rem;
  border: none;
  border: 0.5rem solid transparent;
  outline: none;
}

.signUp input:focus,
.login input:focus {
  border: 0.5rem solid #0f4c81;
}

.signUp label,
.login label {
  margin: 0 1rem;
  font-size: 1.4rem;
}

div.errors {
  min-height: 1rem;
}

.dietaryInfo__container h1 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.dietaryInfo__container h2 {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}

.dietaryInfo__container p {
  margin-bottom: 0.5rem;
}

.pantry__section__container {
  padding: 0.5rem;
  background-color: #c2e2d9;
  text-align: center;
  min-height: 100vh;
}

.myPantry__container {
  padding: 1rem;
}

.wholePantrySearch__container {
  padding: 1rem;
}

.wholePantrySearchBtn {
  padding: 1.5rem;
  background-color: #519d87;
  color: #f5f5f5;
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: 1rem;
  border: 0.5rem solid transparent;
  cursor: pointer;
}

.wholePantrySearchBtn:hover {
  background-color: #f5f5f5;
  color: #519d87;
  border: 0.5rem solid #519d87;
}

.pantry__templateResult {
  background-color: #519d87;
  border-radius: 1rem;
  filter: drop-shadow(0.25rem 0.25rem 0.25rem black);
  min-width: 50%;
  width: fit-content;
  margin: 0 auto;
}

.pantry__templateResult h1 {
  margin: 0 0 1rem 0;
  color: #f5f5f5;
  filter: drop-shadow(0.1rem 0.1rem 0.1rem black);
}

.pantry__templateResult .imageContainer {
  background-color: #ddd;
  border-radius: 1rem 1rem 0 0;
}

.pantry__templateResult .text_section {
  padding: 1rem 3rem 2rem;
  width: 40ch;
  margin: 0 auto;
}

.pantry__templateResult .text_section ol {
  padding-inline-start: 0;
  margin: 0 auto;
}

.pantry.imageContainer {
  padding: 0 0.5rem;
  height: 20rem;
  width: 100%;
}

.pantryImage.templateImage {
  max-width: 20rem;
  height: 20rem;
}

.pantryItems__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

.pantryItem__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #519d87;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0.1em 0.1em 0.5rem;
}

.quantityInput__form {
  border-radius: 0.5rem;
  padding: 0.5rem 0;
}

.pantryItem__quantity__inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pantryItem__quantityInput {
  width: 5rem;
  font-size: 1.2rem;
}

.pantryItem__quantityInput__unitOptions {
  width: 5rem;
  height: 2rem;
}

.pantryItem__quantityInput__unitOptions__option {
  font-size: 1rem;
}

.saveBtn {
  background-color: #333;
}

.pantrySort__label {
  font-weight: 500;
}

.image__container.pantryItem {
  background-color: white;
  text-align: center;
  min-width: 10rem;
  width: auto;
  min-height: 10rem;
  height: auto;
  margin: 1rem;
  border-radius: 0.5rem;
}

.card__body,
.card__body button {
  font-size: 1.2rem;
}

.card__body p {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

p.card__title {
  font-size: 1.6rem;
  font-weight: bold;
}

.ingredientSearch__noResults {
  margin: 2rem auto;
  font-size: larger;
  width: 50ch;
}

.pantrySearch__container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto 2rem;
}

.pantrySearchBar {
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0.25rem 0.25rem 0.25rem black);
}

.innerPantrySearch {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.searchBar__icons__container {
  background-color: white;
  padding: 0.1rem;
  border: 0.5rem solid transparent;
  border-left: none;
  border-radius: 0 1rem 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons {
  width: 1.5rem;
  height: 1.2rem;
  opacity: 0.5;
}

.searchBtn {
  background-color: white;
  border: none;
  cursor: pointer;
  padding: 0;
  padding-right: 0.2rem;
}

.searchBtn:hover .icons {
  opacity: 1;
}

.clearBtn {
  border: none;
  background-color: white;
  cursor: pointer;
  padding: 0.4rem;
}

.clearBtn .icons {
  width: 1.2rem;
  height: 1rem;
}

.clearBtn:hover .icons {
  opacity: 1;
}

.suggestions__container {
  position: absolute;
  top: 4rem;
  z-index: 99;
  margin: 0 auto;
  margin-top: -0.75rem;
  width: 90%;
  max-width: 56.4rem;
  height: fit-content;
  max-height: 75vh;
  background-color: white;
  border-radius: 0 0 1rem 1rem;
  filter: drop-shadow(0 0.1rem 0.2rem black);
  overflow: scroll;
}

.suggestions__list {
  cursor: pointer;
  overflow: scroll;
  margin: 0 auto;
  transition: all 0.1s ease-in-out;
}

.suggestionItem {
  vertical-align: middle;
  padding: 2rem;
}

.suggestionItem:hover,
.suggestionItem:hover .plusBtn {
  background-color: #ddd;
}

.suggestionItem:last-child {
  border-radius: 0 0 1rem 1rem;
}

.suggestionItem p {
  display: inline;
  margin: 0;
}

.singleIngredient__container {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  vertical-align: middle;
  align-items: center;
}

.singleIngredient__imageContainer {
  height: 6rem;
  width: 5rem;
  border-radius: 1rem;
}

.plusBtn {
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
  justify-self: flex-end;
  opacity: 0.5;
}

.plusBtn:hover {
  opacity: 1;
}

.saveBtn,
.updateBtn,
.cancelBtn,
.addQuantityBtn,
.editQuantityBtn,
.deleteBtn,
.ingredientSearchBtn,
.seeMethodBtn,
.saveRecipeBtn,
.unsaveRecipeBtn,
.logoffBtn {
  cursor: pointer;
  border-radius: 1rem;
  border: none;
  border: 0.2rem solid transparent;
  background-color: #f5f5f5;
  margin: 0.5rem 0.25rem;
}

.showMoreBtn,
.showLessBtn {
  cursor: pointer;
  border-radius: 1rem;
  border: none;
  border: 0.2rem solid transparent;
  margin: 0.5rem 0.25rem;
}

.showMoreBtn:hover,
.showLessBtn:hover,
.saveBtn:hover,
.updateBtn:hover,
.cancelBtn:hover,
.addQuantityBtn:hover,
.editQuantityBtn:hover,
.deleteBtn:hover,
.ingredientSearchBtn:hover,
.seeMethodBtn:hover,
.saveRecipeBtn:hover,
.unsaveRecipeBtn:hover {
  background-color: #0f4c81;
  color: #f5f5f5;
}

.logoffBtn:hover,
.accountMgmt__actions .deleteBtn:hover {
  background-color: #d02d05;
  color: #f5f5f5;
}

.pantrySearch__container h1,
.pantrySearch__container h2,
.myPantry__container h3 {
  margin-bottom: 0.5rem;
}

.myPantry__container h1 {
  margin-bottom: 1rem;
}

.recipeSearch__container {
  background-color: #c2e2d9;
  padding: 1rem 1rem 2rem 1rem;
  min-height: 100vh;
}

.recipeSearch__templateResult {
  background-color: #519d87;
  border-radius: 1rem;
  filter: drop-shadow(0.25rem 0.25rem 0.25rem black);
  min-width: 50%;
  width: fit-content;
  margin: 0 auto;
}

.recipeSearch__templateResult h1 {
  margin: 0;
  margin-top: 1rem;
  color: white;
  filter: drop-shadow(0.1rem 0.1rem 0.1rem black);
}

.recipeSearch__templateResult .imageContainer {
  background-color: #ddd;
  border-radius: 1rem 1rem 0 0;
  padding: 0 0.5rem;
  height: 20rem;
  width: 100%;
}

.recipeSearch__templateResult .text_section {
  padding: 1rem;
  width: 40ch;
  margin: 0 auto;
}

.recipeSearch__templateResult .text_section ol {
  padding: 0 2rem;
}

.recipeSearch .recipeImage {
  max-width: 20rem;
  height: 20rem;
}

.searchInput {
  border-radius: 1rem 0 0 1rem;
  padding: 0.5rem;
  width: 80%;
  max-width: 50rem;
  outline: none;
  border: 0.5rem solid transparent;
  border-right: none;
  background-color: white;
  color: #0f4c81;
}

.searchInput:focus {
  border: 0.5rem solid #519d87;
  border-right: none;
}

.searchInput:focus ~ .searchBar__icons__container {
  border: 0.5rem solid #519d87;
  border-left: none;
}

.searchInput:focus ~ .suggestions__container {
  border: 0.5rem solid #519d87;
}

.recipeSearchBar__container {
  padding-bottom: 1.5rem;
}

.recipeSearchBar__container h1,
.recipeSearchBar__container h2 {
  margin-bottom: 0.5rem;
}

.recipeSearchBar__container h3 {
  margin-bottom: 1rem;
  color: black;
  filter: none;
}

.recipeSearchBar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.recipeSearchBar__container .searchInput {
  width: 80%;
}

.innerRecipeSearch {
  display: flex;
  justify-content: center;
  width: 80%;
  filter: drop-shadow(0.25rem 0.25rem 0.25rem black);
}

.recipeSearchResults__list {
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  gap: 1rem;
}

p.sub-heading {
  text-decoration: underline;
}

.savedRecipeSection__container {
  position: relative;
  padding: 1rem;
  background-color: #c2e2d9;
  min-height: 100vh;
}

.savedRecipes__container__grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 90vw;
  height: 90vh;
  border-radius: 1.2rem;
  background-color: #519d87;
  filter: drop-shadow(0.25rem 0.25rem 0.25rem black);
  display: flex;
  flex-direction: column;
  padding: 2rem;
  overflow: scroll;
}

.modalBody .imageContainer {
  width: 60%;
  margin: 0 auto;
}

.modalTitle h1 {
  margin: 0 auto 1rem;
  color: white;
  filter: drop-shadow(0.1rem 0.1rem 0.1rem #0f4c81);
}

.ingredients__container h1 {
  text-align: center;
  margin: 0 0 1rem 0;
  color: #0f4c81;
  filter: drop-shadow(0.1rem 0.1rem 0.1rem white);
}

.ingredients__list {
  text-align: left;
}

.modalCloseBtn {
  align-self: flex-end;
  background: none;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
  width: 3.5rem;
}

.modalCloseBtn svg {
  width: 100%;
  height: 100%;
  color: white;
}

.modalFooter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalFooter button {
  cursor: pointer;
}

.savedRecipes .recipeImage {
  width: inherit;
}

.savedRecipes__templateResult {
  background-color: #519d87;
  border-radius: 1rem;
  filter: drop-shadow(0.25rem 0.25rem 0.25rem black);
  min-width: 50%;
  width: fit-content;
  margin: 0 auto;
}

.savedRecipes__templateResult h1 {
  color: white;
  filter: drop-shadow(0.1rem 0.1rem 0.1rem black);
}

.savedRecipes__templateResult .imageContainer {
  background-color: #ddd;
  border-radius: 1rem 1rem 0 0;
  padding: 0 0.5rem;
  height: 20rem;
  width: 100%;
}

.savedRecipe.text_section {
  padding: 1rem;
  width: 40ch;
  margin: 0 auto;
}

.savedRecipe.text_section ol {
  padding-inline-start: 1rem;
  padding: 0 2rem;
}

.savedRecipeImage {
  max-width: 20rem;
  height: 20rem;
}

.savedRecipe__buttons {
  margin-top: 0.5rem;
}

.singleRecipe__container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #519d87;
  border-radius: 0.5rem;
  min-height: fit-content;
  min-width: fit-content;
  padding: 1.5rem;
  filter: drop-shadow(0.25rem 0.25rem 0.25rem black);
  color: #f5f5f5;
}

.singleRecipe__container h2,
h4 {
  filter: drop-shadow(0.2rem 0.2rem 0.2rem #0f4c81);
  margin-bottom: 0.8rem;
  font-size: medium;
}

.singleRecipe__container img {
  border-radius: 0.5rem;
}

.recipeImage {
  width: 100%;
  border-radius: 0.4rem;
}

.recipeItem.text_section {
  display: flex;
  flex-direction: column;
}

.additionalIngredients__list,
.usedIngredients__container,
.recipeInfo__list,
.ingredients__list,
.recipeInfo__diets {
  padding: 1rem;
  color: #0f4c81;
  filter: drop-shadow(0.1rem 0.1rem 0.1rem black);
  border-radius: 0.5rem;
  background-color: #f5f5f5;
  margin: 1rem auto;
  width: 90%;
}

.recipeInfo__list li,
.ingredients__list li {
  font-size: 1.2rem;
  font-weight: 500;
}

.additionalIngredients__list p,
.usedIngredients__container p,
.recipeInfo__diets p {
  margin: 0;
  margin-bottom: 0.25rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.additionalIngredients__list li,
.usedIngredients__container li,
.recipeInfo__diets li {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
}

.additionalIngredients__list ul.typographic {
  padding-right: 2rem;
}

.recipeMethod__container {
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #f5f5f5;
  color: #0f4c81;
  margin: 0.5rem auto;
  width: 90%;
}

.recipeMethod__container h5 {
  font-size: large;
  margin-bottom: 0.5rem;
  text-decoration: underline;
}

.recipeMethod__container ol {
  padding-inline-start: 2rem;
}

.singleRecipe__container a {
  display: inline-block;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: antiquewhite;
}

.singleRecipe__container a:hover {
  background-color: #333;
  color: white;
  text-decoration: underline;
}

.recipeButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.account__container {
  background-color: #c2e2d9;
  padding: 1rem 1rem 2rem 1rem;
  height: 100%;
}

.account__container > h2 {
  margin-bottom: 1rem;
  color: #6d6875;
  filter: drop-shadow(0.1rem 0.1rem 0.1rem black);
}

.accountInfo__container {
  background-color: #519d87;
  border-radius: 1rem;
  filter: drop-shadow(0.25rem 0.25rem 0.25rem black);
  padding: 1.5rem 2.5rem;
  width: fit-content;
  min-width: 30rem;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.accountInfo__container input {
  border-radius: 1.5rem;
  border: 0.5rem solid transparent;
  padding: 1rem;
  outline: none;
}

.accountInfo__container input:focus {
  border: solid 0.5rem #0f4c81;
}

.accountInfo__container h2 {
  margin-bottom: 0.5rem;
}

.accountInfo__container h2,
h3,
label {
  color: white;
  filter: drop-shadow(0.1rem 0.1rem 0.1rem black);
}

.accountInfo__label {
  display: block;
  margin-bottom: 1rem;
}

.account__dietaryInfo__container {
  background-color: #519d87;
  border-radius: 1rem;
  filter: drop-shadow(0.25rem 0.25rem 0.25rem black);
  padding: 1.5rem 3rem;
  width: fit-content;
  min-width: 30rem;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.account__dietaryInfo__container h2 {
  color: white;
  filter: drop-shadow(0.1rem 0.1rem 0.1rem black);
}

.dangerZone__container {
  background-color: #519d87;
  width: 40rem;
  margin: 0 auto;
  padding: 1.5rem 3rem;
  border-radius: 1rem;
  filter: drop-shadow(0.25rem 0.25rem 0.25rem black);
  margin-bottom: 2rem;
  text-align: left;
  transition: all 0.05s linear;
  font-size: smaller;
}

.dangerZone__container h2 {
  color: #f5f5f5;
  filter: drop-shadow(0.1rem 0.1rem 0.1rem black);
  font-size: large;
  text-align: center;
}

.accountMgmt__actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup__redirect {
  margin: 0 auto;
  margin-top: 3rem;
  width: 80%;
  border-top: solid 0.1rem #333;
}

.signup__redirect p {
  margin: 0.5rem;
}

.mainNav {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #519d87;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
}

.mainNav a {
  border-left: solid 0.15rem black;
  border-bottom: solid 0.15rem black;
  font-weight: 400;
  font-size: 1.4rem;
  padding: 1rem;
  width: 100%;
  min-width: fit-content;
  height: 100%;
}

.activePage {
  color: #519d87;
  background-color: #f5f5f5;
}

.mainNav a:first-of-type {
  border-left: none;
}

.mainNav a:hover {
  color: #519d87;
  cursor: pointer;
  background-color: #f5f5f5;
}

@media screen and (min-width: 540px) {
  .pantryItems__container {
    grid-template-columns: repeat(3, 1fr);
  }

  .searchBar__icons__container {
    padding: 0.25rem;
  }

  .savedRecipes__container__grid {
    grid-template-columns: repeat(2, 1fr);
    margin: 0;
    gap: 1rem;
  }
  .mainNav a {
    font-size: 1.8rem;
    padding: 1rem;
    display: flex;
    justify-content: space-around;
  }

  body,
  li {
    font-size: 1.5rem;
  }

  .dietaryInfo__container h1 {
    font-size: 2.4rem;
    margin-bottom: 0.5rem;
  }

  .dietaryInfo__container h2 {
    font-size: 2.2rem;
    margin-bottom: 0.5rem;
  }

  .onboarding__container {
    width: 65vw;
    min-height: fit-content;
    max-height: 100%;
  }

  button {
    font-size: 1.2rem;
  }

  .searchBtn,
  .clearBtn {
    padding: 0.5rem;
  }

  .icons,
  .clearBtn .icons {
    height: 1.5rem;
  }

  .suggestions__container {
    top: 4.5rem;
  }

  .signUp {
    flex-wrap: nowrap;
  }

  .signup__redirect {
    width: 70%;
  }
}

@media screen and (min-width: 720px) {
  .pantryItems__container {
    grid-template-columns: repeat(4, 1fr);
  }

  .savedRecipes__container__grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .mainNav a {
    font-size: 2rem;
  }

  body {
    font-size: 1.6rem;
  }

  .onboarding__container {
    width: 60%;
    height: fit-content;
  }

  .signUp__container {
    min-height: fit-content;
  }

  .signup__redirect {
    width: 60%;
  }

  .searchBtn,
  .clearBtn {
    padding: 0.55rem;
  }
  .singleIngredient__imageContainer {
    height: 8.5rem;
    width: 7.5rem;
  }
}

@media screen and (min-width: 960px) {
  .pantryItems__container {
    grid-template-columns: repeat(5, 1fr);
  }

  .savedRecipes__container__grid {
    grid-template-columns: repeat(4, 1fr);
  }
  .mainNav a {
    font-size: 2.5rem;
  }
}

@media screen and (min-width: 1140px) {
  .pantryItems__container {
    grid-template-columns: repeat(6, 1fr);
  }

  .savedRecipes__container__grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
